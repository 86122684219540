* {
  margin: 0;
  padding: 0;
}

* ::before,
  ::after {
    box-sizing: inherit;
  }

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}  

/* 1200px / 16px = 75em */
@media (max-width: 75em) {
  html {
    font-size: 60%;
  }
}

/* 980px / 16px = 61.25em */
@media (max-width: 61.25em) {
  html {
    font-size: 58%;
  }
}

/* 460px /16px = 28.75em */
@media (max-width: 28.75em) {
   html {font-size: 55%;}
}

body {
  font-family: 'poppins', sans-serif;
  font-weight: 400;
  line-height: 1.4;
  box-sizing: border-box;
  background: #000000;
  background-image: url('./images/landing.jpg');
  /* background-image: linear-gradient(179deg, rgba(0,0,0,0.398) 5%, rgba(0,0,0,6) 90%, rgba(0,0,0,6) 100%), url('./images/landing.jpg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff
}
/* 0.48783263305322133 */
.main-container {
  flex-grow: 1;
}

.text-center {
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.btn-primary {
  background-color: #8b0000!important;
  color: white!important;
}

.btn-primary:hover {
  background-color: #680303!important;
}

.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (max-width:1200px) {
  .main-container{
     position: relative;
     top: 0;
     left: 0;
     transform: none;
     margin-top: 100px;
     padding-bottom: 100px;
  }
}