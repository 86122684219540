* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .loading {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box {
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-reflect: below 0 linear-gradient(transparent, transparent, #0005);
    transition: all 0.8s ease-in-out;
    transform: scale(1.5);
  }
  
  .box .loader {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    animation: animateLoading 2s linear infinite;
  }
  
  .box .loader:nth-child(2),
  .box .loader:nth-child(4) {
    animation-delay: -1s;
    filter: hue-rotate(290deg);
  }
  @keyframes animateLoading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .box .loader:nth-child(1)::before,
  .box .loader:nth-child(2)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to top, transparent, rgba(255, 0, 0, 0.5));
    background-size: 100px 180px;
    background-repeat: no-repeat;
    border-bottom-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .loading .loader {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50);
    width: 30px;
    height: 30px;
    background: #00fff9;
    border-radius: 50%;
    box-shadow: 0 0 10px #00fff9, 0 0 20px #00fff9, 0 0 30px #00fff9,
      0 0 40px #00fff9, 0 0 50px #00fff9, 0 0 60px #00fff9, 0 0 70px #00fff9,
      0 0 80px #00fff9, 0 0 90px #00fff9, 0 0 100px #00fff9;
    z-index: 10;
  }
  .box .loader .span {
    position: absolute;
    inset: 20px;
    background: #000;
    border-radius: 50%;
    z-index: 10;
  }
