.containerPpal {
  display: flex;
  flex-direction: column;
  height: 120vh;
}

/* Página dividida en 3 secciones*/
.containerHeader {
  display: flex;
  width: 98%;
  height: 10%;
  padding-left: 1%;
  padding-right: 1%;
  align-items: center;
}

.containerCuerpoPpal {
  display: flex;
  margin: auto;
  width: 100%;
  height: 100vh;
}

.containerConJuegos {
  display: flex;
  width: 100%;
  height: 100%;
}

.containerFooter {
  color: white;
  display: flex;
  width: 100%;
  height: 5%;
}

/* Estilos del container header*/
.addGame {
  width: 25%;
}

.title {
  width: 50%;
  font-size: 1.3rem;
  font-weight: 500;
  color: #fff;
  text-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 100px #03e9f4;
  letter-spacing: 5px;
  cursor: pointer;
  text-transform: uppercase;
  transition: 1s;
}

.title:hover {
  color: #fff;
  text-shadow: 0 0 50px #03e9f4, 0 0 100px #03e9f4;
}

.searchbar {
  display: flex;
  width: 25%;
}

.boton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  width: 50%;
  font-size: 1.3rem;
  background-color: #1d1d1d;
  color: #ffffff;
  font-weight: bold;
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
}

.boton:hover {
  background: #fff;
  color: red;
  font-weight: 500;
  font-weight: 600;
}

/* Estilos del container Cuerpo Ppal*/
.filtros {
  display: flex;
  flex-direction: column;
  width: 15%;
  color: black;
}

.videogamesBox {
  width: 160%;
  height: 97%;
  display: grid;
  margin-top: 10px;
  gap: 40px;
  padding: 40px;
  justify-content: center;
}

.cardsContainer {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  /* border: 1px solid #ffff; */
}

.paginado {
  display: flex;
  align-items: center;
  height: 2%;
}

/* Estilos del container filtros*/
.filterBox {
  width: 18%;
  height: 20%;
  display: flex;
  color: #ffff;
  position: fixed;
}

.orderBox {
  display: flex;
  height: 27%;
  width: 110%;
  color: #ffff;
  /* border: 1px solid #ffff; */
}

.container {
  width: 100vw;
  height: 75vh;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards {
  /* max-width: 1280px; */
  margin: 0 auto;
  margin-bottom: 6rem;
  height: 99%;
  width: 99%;
  overflow: hidden;
}

.games {
  display: inline-flex;
  flex-direction: column;
  width: 27vw;
  height: 36vh;
  background: #355e3b60;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  margin: 10px;
  overflow: hidden;
  border: 1px solid black;
  border-radius: 15px;
  box-shadow: 2px 0 4px #fff;
}
.games:hover {
  transform: scale(1.05);
}

.gameTitle {
  height: 15%;
  font-size: 1.75rem;
  font-weight: 400;
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 0.9345939059217437) 0%,
    rgba(253, 29, 29, 0.9542017490589986) 50%,
    rgba(252, 176, 69, 0.9457983877144608) 100%
  );
}

.gameTitle:hover {
  transform: scale(1.1);
  border: 1px solid #03e9f4;
}

.gameImage {
  height: 90%;
}

.gameRating {
  position: relative;
  /* display: inline-block; */
  font-family: "Open Sans", Arial, Verdana, sans-serif;
  font-weight: bold;
  font-size: 11px;
  left: 10px;
  height: 45%;
  width: auto;
  color: #1a1a1a;
  border-radius: 100px;
  background: orange;
  overflow: hidden;
  padding: 0px 12px 0px 12px;
  line-height: 1.8rem;
}

.gameRating:hover {
  transform: scale(1.3);
  color: #121212;
  font-weight: 700;
  z-index: 11;
}

.gameGenres {
  height: 15%;
  font-size: 1rem;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #355e3b60;
  line-height: 2.85rem;
}

.gameGenres:hover {
  transform: scale(1.1);
  border: 1px solid #03e9f4;
}

.generoSpan {
  height: 100%;
  padding-left: 1%;
  padding-right: 1%;
}

.cardImage {
  width: 75%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #03e9f4;
  transition: all 0.2s ease-in-out;
}

.cardImage:hover {
  transform: scale(1.1);
  border: 1px solid #03e9f4;
}

.navLink {
  text-decoration: none;
  width: fit-content;
  color: #fff;
}
