
.home {
    width: 100vw;
    height: 100vh;
    justify-content: center;
    /* background-size: 100%; */
    /* display: flex; */
    align-items: center;
    
}

.homeButton {
    margin-top: 400px;
    width: 18vw;
    height: 8vh;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #fff;
    background-color: red;
    font-weight: 700;
    font-size: 2.5rem;
    border-radius: 18px;
    cursor: pointer;
    justify-content: center;
    transition: all .2s ease-in-out;
}

.homeButton:hover {
    color: blue;
    background: #fff;
    font-size: 2.8rem;
    transform: scale(1.1);
}