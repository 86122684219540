body{
    margin: 0;
}

.main{
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vh;
    height: 100vh;
    /* justify-content: space-around; */
    align-items: center;
    background-image: url('../../images/gameover.jpg');
    background-color: #FFFFFF !important;
    background-repeat: no-repeat !important;
    background-position: left left !important;
    z-index: auto;
    background-size: cover !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    border-radius: 45px;
}
.load {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: #1a1a1a;
    color: #FFFFFF;
    font-size: 3rem;
}