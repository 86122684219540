.container {
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
  }
  
  .numbersContainer {
    width: 100%;
    padding: 20px;
    align-items: center;
  }
  
  .numbers {
    display: flex;
    margin: auto;
    padding: 20px;
    width: 500px;
    justify-content: space-around;
    align-items: center;
  }
  
  .buttonPagin {
    background-color: black;
    color: #fff;
    font-size: 2rem;
    font-weight: 400;
    width: 30px;
    border: 1px solid #fff;
    border-radius: 25%;
  }
  
  .buttonPagin:hover {
    background: whitesmoke;
    color: black;
  }
  
  .active {
    width: 40px;
    border-radius: 5px;
    background: rgb(32, 158, 36);
    font-size: 2rem;
    color: #fff;
    font-weight: 400;
  }

.button {
  width: 60px;
  height: 30px;
  padding: 1px;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 15px;
}