.container {
    display: flex;
    flex-direction: column;
    margin-top: 57px;
    width: 100%;
    height: 52%;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 2px;
  }
  
  .nameContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40%;
    justify-content: center;
  }
  
  .containerTitle {
    font-weight: bold;
  }
  
  .options {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .checkBox {
    display: flex;
    background-color: gray;
    width: 25%;
    align-items: center;
    justify-content: center;
    font-size: large;
  }
  
  .label {
    display: flex;
    width: 75%;
    align-items: center;
    padding-left: 3%;
  
    justify-content: left;
    font-size: 100%;
  }

