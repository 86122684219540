.body {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #333;
  }
  
  .containerHeader {
    background-color: rgba(0, 0, 0, 0.25);
    color: cornsilk;
    display: flex;
    width: 100%;
    height: 10%;
  
    padding-left: 1%;
    padding-right: 1%;
    align-items: center;
    justify-content: center;
  }
  
  /* Estilos del container header*/
  
  .addGame {
    width: 30%;
  }
  
  .title {
    width: 45%;
    height: fit-content;
    border-radius: 30px 30px 10px 10px;
    background: rgb(131, 58, 180);
    background: linear-gradient(
      90deg,
      rgba(131, 58, 180, 0.9009804605435925) 0%,
      rgba(34, 29, 253, 0.8421569311318278) 50%,
      rgba(69, 237, 252, 0.5060224773503151) 100%
    );
  }
  
  .boton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #fff;
    background: rgb(131, 58, 180);
    background: linear-gradient(
      90deg,
      rgba(131, 58, 180, 0.9009804605435925) 0%,
      rgba(34, 29, 253, 0.8421569311318278) 50%,
      rgba(69, 237, 252, 0.5060224773503151) 100%
    );
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
  }
  
  /* Estilos del cuerpo*/
  .presentation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55%;
    width: 100%;
    padding: 1%;
    /* background-color: #f2494980; */
    background: rgb(131, 58, 180);
    background: linear-gradient(
      90deg,
      rgba(131, 58, 180, 0.9009804605435925) 0%,
      rgba(34, 29, 253, 0.8421569311318278) 50%,
      rgba(69, 237, 252, 0.5060224773503151) 100%
    );
    /* background-color: gainsboro; */
  }
  
  .containerImagen {
    width: 50%;
    height: 100%;
    padding: 1%;
    display: flex;
    justify-content: space-around;
  }
  
  .image {
    width: 95%;
    display: flex;
    height: 95%;
    justify-content: center;
    align-items: flex-end;
    padding: 2px;
    border-radius: 25px 80px 10px 25px;
    box-shadow: 10px -2px 25px -6px rgba(240, 136, 18, 0.84);
    -webkit-box-shadow: 10px -2px 25px -6px rgba(240, 136, 18, 0.84);
    -moz-box-shadow: 10px -2px 25px -6px rgba(240, 136, 18, 0.84);
  }
  
  .data {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 2.5rem;
  }
  
  .titles {
    width: 150px;
    display: flex;
    background-color: black;
    color: whitesmoke;
    padding-left: 2%;
    font-size: 2.5rem;
    border-radius: 0 20px 0 20px;
  }
  
  .datos {
    display: flex;
    justify-content: space-around;
    width: 80%;
    font-size: 2.5rem;
    font-weight: 500;
    color: #fff;
    background-color: #f7e99c60;
    border-radius: 5px;
    box-shadow: 0 0 5px #3d8d3d;
  }
  
  .datosGenresSpan {
    justify-content: space-between;
    text-align: justify;
    flex-wrap: wrap;
    padding: 5px;
  }
  
  .datosPlatforms {
    justify-content: space-between;
    text-align: justify;
    flex-wrap: wrap;
    padding: 5px;
  }
  
  .description {
    height: 35%;
    color: #333;
    background-color: #333080;
    font-weight: bold;
    font-size: 2.1rem;
  }
  
  .description p {
    font-size: 2.1rem;
    text-align: justify;
    padding: 20px;
    color: #ffff;
  }
  
  .titleDescription {
    background-color: black;
    color: whitesmoke;
    font-size: 150%;
    margin: 0;
  }
  
  .descriptionText {
    background-color: rgba(49, 65, 65, 0.7);
    /* background-image: url('../../images/Henry.png'); */
    image-orientation: inherit;
    
  }
  
  .idGame {
    background: #1a1a1a;
  }
  
  .navLink {
    text-decoration: none;
    color: white;
    font-weight: bold;
  }