#container {
    display: flex;
    height: 30%;
    width: fit-content;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 150px;
    overflow-y: scroll;
    padding: 40px;
    box-sizing: border-box;
    align-items: stretch;
    justify-content: space-between;
    background: rgba(58,180,151, 0.05);
    
}

#container h4 {
    font-weight: bold;
    display: block;
    
}

#genreContainer {
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: #1a1a1a;
    border-radius: 3px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff
    
    /* display: flex;
    flex-direction: column;
    height: 40%;
    /* margin: 0; */
    /*padding: 0;
    border-radius: 5px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff */
}

.title{
    height: 25%;
    
}

#listSelection {
    height: auto;
    width: fit-content;
    font-size: 1rem;
    color: red;
    border:1px solid red;
}

.genresOpt{
    width: auto;
    height: fit-content;
    text-align: center;
    color: #fff;
    background: #1a1a1a;
}

#filterOptBox{
    display: flex;
    height: fit-content;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.options{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: fit-content;
    width: fit-content;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    
    
}

.genresSelNames{
    width: fit-content;
    height: 25%;
    padding: 5%;
    justify-content: center;
    color: red;
    
}

#sourceContainer {
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: #1a1a1a;
    border-radius: 3px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff
}



